import React, { useContext, useEffect, useState } from "react";
import { Accordion, ListGroup, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import { createClient } from "@supabase/supabase-js";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai"
import { ChatAppContext } from "../../../Context/AppContext";
import PlanCard from "../../../Components/PlanCard";
import { usePlan } from "../../../Context/PlanContext";
import { useApi } from "../../../Context/ApiContext";
import ChatModal from "../../../Components/ChatModal";

const SideBarRedesigned = () => {

  const { apiSettings } = useApi();
  const sbUrl = apiSettings.supabaseUrl;
  const sbApiKey = apiSettings.supabaseApiKey;
  const { newChat } = useContext(ChatAppContext);
  const navigate = useNavigate();
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const { isPlanActive, TokensAvailable, userData, getProfileInfo } = usePlan();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [noPermission, setNoPermission] = useState();
  const [userChats, setUserChats] = useState([]);
  const [UserData, setUserData] = useState({});
  const [selectedChat, setSelectedChat] = useState("Chats");
  const [selectedChatFiles, setSelectedChatFiles] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isChatAccordionOpen, setIsChatAccordionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChatId, setActiveChatId] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = (e) => {
    e.preventDefault();
    Helpers.toast("success", "Logged out successfully");
    Helpers.authUser = null;
    localStorage.clear();
    navigate("/");
  };

  const estimateTokensForVectorization = (text) => {
    const tokens = text.split(/\s+/).length;
    return tokens;
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);

    }
  };
  useEffect(() => {
    if (userData) {
      if (userData.permissions === 1) {
        setNoPermission(true);
      } else {
        setNoPermission(false);
      }
      setIsUserDataLoading(false);
    }
  }, [userData]);
  useEffect(() => {
    const savedState = localStorage.getItem('sidebarCollapsed') === 'true';
    setIsCollapsed(savedState);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("Collapse Value : ", isCollapsed);
  }, [isCollapsed]);

  const toggleCollapse = () => {
    setIsCollapsed((prevState) => {
      const newState = !prevState;
      localStorage.setItem('sidebarCollapsed', newState);
      return newState;
    });
  };

  const NavigateToChatPage = (chats) => {
    setSelectedChat(chats.chat_detail);
    setSelectedChatFiles(chats.file);
    setActiveChatId(chats.chat_id);
    setIsAccordionOpen(false);
    navigate(`/chat/chatbot/${chats.chat_id}`);
  };

  const getPreviousChats = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user_chats = await axios.get(
          `${Helpers.apiUrl}chat/all`,
          Helpers.authHeaders
        );
        console.log(user_chats)
        if (user_chats) {
          const chats = user_chats.data.map((datum) => ({
            chat_detail: datum.file_content.replace(/\n/g, " ").slice(15, 40),
            chat_id: datum.chatid,
            file: JSON.parse(datum.file),
          }));
          setUserChats(chats);
          if (chats.length > 0) {
            setSelectedChat(chats[0].chat_detail);
            setSelectedChatFiles(chats[0].file);
            setActiveChatId(chats[0].chat_id);
          } else {
            setSelectedChat("Chats");
          }
        } else {
          console.log("No Chats");
          setSelectedChat("Chats");
        }
      } else {
        console.log("No user found in localStorage");
        setSelectedChat("Chats");
      }
    } catch (e) {
      console.log("Error: ", e);
      setSelectedChat("Chats");
    }
  };

  useEffect(() => {
    const storeUser = localStorage.getItem("user");
    if (storeUser) {
      try {
        const user = JSON.parse(storeUser);
        if (user && user.id) {
          setUserData({
            user_id: user.id,
            email: user.email,
            name: user.name,
          });
        } else {
          console.log("Invalid user data in localStorage");
        }
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    } else {
      console.log("No user found in localStorage");
    }

    getPreviousChats();
  }, []);

  useEffect(() => {
    if (newChat) {
      setUserChats((prevChats) => [...prevChats, newChat]);
      setSelectedChat(newChat.chat_detail);
      setSelectedChatFiles(newChat.file);
      setActiveChatId(newChat.chat_id);
    }
  }, [newChat]);

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const toggleChatAccordion = () => {
    setIsChatAccordionOpen(!isChatAccordionOpen);
  };

  const handleUpload = async (file, chatid) => {
    if (file && chatid) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("chatid", chatid);
        formData.append("prompt_id", 1);

        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );

        console.log("File uploaded response: ", response);
        if (response) {
          setSelectedChatFiles([file.name]);
          await getPreviousChats();

          const textResponse = await axios.get(
            `${Helpers.apiUrl}chat/get/${chatid}`,
            Helpers.authFileHeaders
          );
          const chatData = textResponse.data.file_content;

          const splitter = new RecursiveCharacterTextSplitter({
            chunkSize: 500,
            chunkOverlap: 50,
            separators: ["\n\n", "\n", " ", ""],
          });
          const output = await splitter.createDocuments([chatData]);

          let totalTokensForVectorization = 0;
          output.forEach((chunk) => {
            totalTokensForVectorization += estimateTokensForVectorization(chunk.pageContent);
          });
          console.log("Estimated tokens for vectorization:", totalTokensForVectorization);

          const client = createClient(sbUrl, sbApiKey);
          await Promise.all([
            SupabaseVectorStore.fromDocuments(
              output,
              new OpenAIEmbeddings({
                openAIApiKey: apiSettings.openAiApiKey
              }),
              {
                client,
                tableName: "docsphere_documents",
              }
            ),
            axios.post(
              `${Helpers.apiUrl}updateTokens`,
              { tokens: totalTokensForVectorization },
              Helpers.authHeaders
            ),
          ]);
          Helpers.toast("success", "File uploaded successfully to the existing chat");
          await getProfileInfo();
          console.log("Vectorization complete");
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.message);
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", "Unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file or chatId");
    }
  };


  const handleFileChange = (e) => {
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setSelectedChatFiles([]);
      handleUpload(file, activeChatId);
    }
  };

  return (
    <div
      className={` d-flex overflow-auto pr-2 scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin flex-column h-100 position-relative`}>
      <ChatModal
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
      />
      <div className="flex-grow-2 mb-12 h-auto">
        <div className={`flex items-center  justify-between py-2 ${isCollapsed ? "flex-col items-center" : "flex-row"}`} >
          <div className={`flex-shrink-0 ${isCollapsed ? "" : "py-2"}`}>
            <Link to="/user/profile">
              <img
                className={`rounded-full ${isCollapsed ? "h-8 w-8" : "h-10 w-10"}`}
                src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                alt="author avatar"
              />
            </Link>
          </div>
          {!isCollapsed && (
            <div className="info-container flex-1 pl-2 m-0">
              <div className="text-xs font-semibold text-black">{UserData.name}</div>
              <div className="text-xs">{UserData.email}</div>
            </div>
          )}

          <div
            className={`flex-shrink-0 cursor-pointer ${isCollapsed ? "mt-2  btn btn-dark sm:!btn-primary" : ""}`}
            onClick={logout}
          >
            <i className="fal fa-power-off"></i>
          </div>
        </div>
        <div className={`flex items-center mt-1 mb-3 justify-between ${isCollapsed ? "flex-col items-center" : ""}`}>
          <a className={`${isCollapsed ? " " : "w-100"} ${(isPlanActive === 'Expired' || noPermission) ? 'pointer-events-none' : ''}`}
            onClick={() => setShow(true)}>
            <button
              className={`btn btn-dark ${isCollapsed ? "text-center w-auto py-1" : "w-100"}`}
              type="button"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="radix-:r0:"
              data-state="closed"
              disabled={isPlanActive === 'Expired' || noPermission}
            >
              {isCollapsed ? (
                <span>
                  <i className="fal fa-plus"></i>

                </span>
              ) : (
                <div className="flex gap-1">
                  <span>
                    <i className="fal fa-plus"></i>
                  </span>
                  <span className="d-inline">New Chat</span>
                </div>
              )}
            </button>
          </a>
        </div>

        {!isCollapsed && (
          <div>
            <div className="">
              <h6 className="text-left p-2 font-semibold">
                Your Chats
              </h6>
              <Accordion style={{ maxWidth: "250px" }} activeKey={isAccordionOpen ? "0" : null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    className="truncate w-full"
                    onClick={toggleAccordion}
                  >
                    {selectedChat}
                  </Accordion.Header>
                  <Accordion.Body className="scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin" style={{ maxHeight: "300px", maxWidth: "250px", overflowY: "auto" }}>
                    {userChats.map((chats, index) => (
                      <ListGroup.Item key={index} className="fit-content">
                        <div
                          onClick={() => NavigateToChatPage(chats)}
                          className="d-flex align-items-center py-1 mt-1 cursor-pointer text-small hover:bg-secondary transition-all rounded-3 "
                        >
                          <div className="text-truncate ">{chats.chat_detail}</div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="w-full h-0.5 bg-gray-300 rounded-lg"></div>
          </div>
        )}

        <div className="mt-0">
          {!isCollapsed && Helpers.authUser.file_history?.length > 0 && (
            <div className=" pb-2">
              <h6 className="text-left p-2 font-semibold">Uploaded Files</h6>
              <Accordion style={{ maxWidth: "250px" }} activeKey={isChatAccordionOpen ? "1" : null}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="truncate w-full" onClick={toggleChatAccordion}>
                    Uploaded Files
                  </Accordion.Header>
                  <Accordion.Body
                    className="scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin"
                    style={{ maxHeight: "300px", maxWidth: "250px", overflowY: "auto" }}
                  >
                    {Helpers.authUser.file_history?.length > 0 ? (
                      Helpers.authUser.file_history.map((file, index) => (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span className="text-truncate text-black py-1">{`${index + 1} ${file.file_name}`}</span>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <p>No uploaded files.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          )}
          <div className={`position-relative flex justify-evenly ${(isPlanActive === 'Expired' || noPermission) ? 'pointer-events-none opacity-50' : ''}`}>
            <input
              type="file"
              id="file-upload"
              className="form-control d-none"
              onChange={handleFileChange}
              disabled={isLoading || !activeChatId || noPermission}
            />

            <label htmlFor="file-upload" className={`btn btn-dark ${isCollapsed ? "text-center w-auto" : "w-100"}`}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Uploading...
                </>
              ) : isCollapsed ? (
                <i className="fal fa-file-upload"></i>
              ) : (
                "Choose File"
              )}
            </label>
          </div>
        </div>
        <div className="mx-auto mb-4">
          {userData && !isCollapsed && (
            (userData.is_org_owner && userData.org_id != null) ||
            (!userData.is_org_owner && userData.org_id == null)
          ) && (<PlanCard />
            )}
        </div>
        <div className="w-100 flex justify-evenly">
          <button className="my- mx-auto btn btn-dark" onClick={toggleCollapse}>
            {isCollapsed ? (
              <i className="fal fa-expand-arrows-alt"></i>
            ) : (
              <div>
                <i className="fal fa-compress-arrows-alt"></i> <span>Collapse</span>
              </div>
            )}
          </button>
        </div>

      </div>
    </div>
  );
};

export default SideBarRedesigned;
