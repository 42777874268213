import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  PDFDownloadLink } from "@react-pdf/renderer";
import MyPDFTemplate from './MyPdfTemplate';
import Helpers from '../../Config/Helpers';
import { useParams } from 'react-router-dom';
import PageLoader from '../../Components/Loader/PageLoader';
const CVTemplate = () => {
  const { chat_id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const cvObject = "{\"name\": \"Jonathan Doe\", \"email\": \"name@yourdomain.com\", \"phone\": \"(313) - 867-5309\", \"profile\": \"Web Designer, Director\", \"skills\": [{\"title\": \"Web Design\", \"description\": \"Assertively exploit wireless initiatives.\"},{\"title\": \"Interface Design\", \"description\": \"Credibly streamline mission-critical value.\"},{\"title\": \"Project Direction\", \"description\": \"Proven ability to lead and manage a wide variety of projects.\"} ], \"technical_skills\": [[\"XHTML\", \"CSS\", \"Javascript\"],[\"Jquery\", \"PHP\", \"CVS / Subversion\"],[\"OS X\", \"Windows XP/Vista\", \"Linux\"] ], \"experience\": [{\"company\": \"Facebook\", \"position\": \"Senior Interface Designer\", \"years\": \"2005-2007\", \"description\": \"Optimal core competencies.\"},{\"company\": \"Apple Inc.\", \"position\": \"Senior Interface Designer\", \"years\": \"2005-2007\", \"description\": \"Outside the box thinking.\"} ], \"education\": [{\"institution\": \"University of Birmingham\", \"degree\": \"BA (Hons) Graphic Design\", \"years\": \"2003-2006\"} ]}";
    axios
        .post(`${Helpers.apiUrl}user/template_data`, { chatid: chat_id, cvObject: cvObject }, Helpers.authHeaders)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [chat_id]);

  return (
    <div>
      {data ? (
        <>
          <PDFDownloadLink
            document={<MyPDFTemplate data={data} />}
            fileName="profile.pdf"
            style={{
              textDecoration: 'none',
              padding: '10px',
              color: '#fff',
              backgroundColor: '#007bff',
              borderRadius: '5px',
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Download PDF'
            }
          </PDFDownloadLink>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default CVTemplate;
