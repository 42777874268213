import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Helpers from "../../../Config/Helpers";
import { Accordion, ListGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { createClient } from "@supabase/supabase-js";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai"

import { ChatAppContext } from "../../../Context/AppContext";
import { usePlan } from "../../../Context/PlanContext";
import PlanCard from "../../../Components/PlanCard";
import { useApi } from "../../../Context/ApiContext";

const NavBarRedesigned = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const { isPlanActive, TokensAvailable, userData, getProfileInfo } = usePlan();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { apiSettings } = useApi();
  const [userChats, setUserChats] = useState([]);

  const [noPermission, setNoPermission] = useState();
  const [selectedChat, setSelectedChat] = useState("Chats");
  const [isLoading, setIsLoading] = useState(false);
  const [isChatAccordionOpen, setIsChatAccordionOpen] = useState(false);
  const [activeChatId, setActiveChatId] = useState(null);
  const [UserData, setUserData] = useState({});
  const { newChat } = useContext(ChatAppContext);
  const sbUrl = apiSettings.supabaseUrl;
  const sbApiKey = apiSettings.supabaseApiKey;

  const estimateTokensForVectorization = (text) => {
    const tokens = text.split(/\s+/).length;
    return tokens;
  };

  const logout = (e) => {
    e.preventDefault();
    Helpers.toast("success", "Logged out successfully");
    Helpers.authUser = null;
    localStorage.clear();
    navigate("/");
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  useEffect(() => {
    if (userData) {
      if (userData.permissions === 1) {
        setNoPermission(true);
      } else {
        setNoPermission(false);
      }
      setIsUserDataLoading(false);
    }
  }, [userData]);
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const toggleChatAccordion = () => {
    setIsChatAccordionOpen(!isChatAccordionOpen);
  };
  const getPreviousChats = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user_chats = await axios.get(
          `${Helpers.apiUrl}chat/all`,
          Helpers.authHeaders
        );
        console.log(user_chats)
        if (user_chats) {
          const chats = user_chats.data.map((datum) => ({
            chat_detail: datum.file_content.replace(/\n/g, " ").slice(15, 40),
            chat_id: datum.chatid,
            file: JSON.parse(datum.file),
          }));
          setUserChats(chats);
          if (chats.length > 0) {
            setSelectedChat(chats[0].chat_detail);
            setActiveChatId(chats[0].chat_id);
          }
        }
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handleUpload = async (file, chatid) => {
    if (file && chatid) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("chatid", chatid);
        formData.append("prompt_id", 1);

        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );

        console.log("File uploaded response: ", response);
        if (response) {
          await getPreviousChats();

          const textResponse = await axios.get(
            `${Helpers.apiUrl}chat/get/${chatid}`,
            Helpers.authFileHeaders
          );
          const chatData = textResponse.data.file_content;

          const splitter = new RecursiveCharacterTextSplitter({
            chunkSize: 500,
            chunkOverlap: 50,
            separators: ["\n\n", "\n", " ", ""],
          });
          const output = await splitter.createDocuments([chatData]);

          let totalTokensForVectorization = 0;
          output.forEach((chunk) => {
            totalTokensForVectorization += estimateTokensForVectorization(chunk.pageContent);
          });
          console.log("Estimated tokens for vectorization:", totalTokensForVectorization);

          const client = createClient(sbUrl, sbApiKey);

          await Promise.all([
            SupabaseVectorStore.fromDocuments(
              output,
              new OpenAIEmbeddings({
                openAIApiKey: apiSettings.openAiApiKey
              }),
              {
                client,
                tableName: "docsphere_documents",
              }
            ),
            axios.post(
              `${Helpers.apiUrl}updateTokens`,
              { tokens: totalTokensForVectorization },
              Helpers.authHeaders
            ),
          ]);
          Helpers.toast("success", "File uploaded successfully to the existing chat");
          await getProfileInfo();
          console.log("Vectorization complete");
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.message);
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", "Unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file or chatId");
    }
  };


  const handleFileChange = (e) => {
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      handleUpload(file, activeChatId);
    }
  };
  const handleChatSelection = (chat) => {
    setSelectedChat(chat.chat_detail);
    setActiveChatId(chat.chat_id);
    setIsAccordionOpen(false);
    setIsExpanded(false);
    navigate(`/chat/chatbot/${chat.chat_id}`);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData({
        user_id: user.id,
        email: user.email,
        name: user.name,
      });
    }
    getPreviousChats();
  }, []);

  useEffect(() => {
    if (newChat) {
      setUserChats((prevChats) => [...prevChats, newChat]);
      setSelectedChat(newChat.chat_detail);
      setActiveChatId(newChat.chat_id);
    }
  }, [newChat]);

  return (
    <div>
      <nav className="navbar px-5 fixed-top d-flex justify-content-between align-items-center p-2 bg-[#F1F2F6] text-white">
        <div className="navbar-item">
          <Link to="/user/profile">
            <img
              className="rounded-circle"
              src={Helpers.serverImage(Helpers.authUser.profile_pic)}
              alt="User Avatar"
              style={{ width: "40px", height: "40px" }}
            />
          </Link>
        </div>

        <div className="navbar-item">
          <button
            className="btn btn-dark rounded-md"
            onClick={toggleExpand}
          >
            {isExpanded ? (
              <i className="fas fa-compress"></i>
            ) : (
              <i className="fas fa-expand"></i>
            )}
          </button>
        </div>
        <div className={`navbar-item ${(isPlanActive === 'Expired' || noPermission) ? 'pointer-events-none opacity-50' : ''}`}>
          <button
            className="btn btn-dark rounded-md"
            onClick={() => navigate("/chat/chat-interface")}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
        <div className="navbar-item">
          <button className="btn btn-dark rounded-md" onClick={logout}>
            <i className="fas fa-power-off"></i>
          </button>
        </div>
      </nav>

      {isExpanded && (
        <div className="expanded-navbar rounded-md bg-white p-3 mt-5">

          {/* <div className="info-container mb-3">
            <div className="font-semibold text-black">{userData.name}</div>
            <div>{userData.email}</div>
          </div> */}

          {/* <div className="search-bar mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Quick Search"
              value={selectedChat}
              onChange={(e) => setSelectedChat(e.target.value)}
            />
          </div> */}

          <h6 style={{ marginTop: "20px" }} className="text-left p-2 font-semibold">Your Chats</h6>
          <Accordion className="" activeKey={isAccordionOpen ? "0" : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={toggleAccordion}>
                {selectedChat}
              </Accordion.Header>
              <Accordion.Body className="space-y-2 scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {userChats.map((chats, index) => (
                  <ListGroup.Item className="border p-2 hover:!border-gray-900 transition duration-200 rounded cursor-pointer" key={index} onClick={() => handleChatSelection(chats)}>
                    {chats.chat_detail}
                  </ListGroup.Item>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {Helpers.authUser.file_history?.length > 0 && (
            <div className="pt-2 pb-2">
              <h6 className="text-left p-2 font-semibold">Uploaded Files</h6>
              <Accordion activeKey={isChatAccordionOpen ? "1" : null}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="truncate w-full" onClick={toggleChatAccordion}>
                    Uploaded Files
                  </Accordion.Header>
                  <Accordion.Body
                    className="scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    {Helpers.authUser.file_history.length > 0 ? (
                      Helpers.authUser.file_history.map((file, index) => (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span className="text-truncate text-black py-1">{`${index + 1} ${file.file_name}`}</span>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <p>No uploaded files.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          <div className={`file-upload mt-3 ${(isPlanActive === 'Expired' || noPermission) ? 'pointer-events-none opacity-50' : ''}`}>
            <input
              type="file"
              id="file-upload"
              className="form-control d-none"
              onChange={handleFileChange}
              disabled={isLoading || !activeChatId}
            />
            <label htmlFor="file-upload" className="btn btn-dark w-100">
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Uploading...
                </>
              ) : (
                "Choose File"
              )}
            </label>
          </div>

          <div className="mx-auto mb-4">
            {userData && (
              (userData.is_org_owner && userData.org_id != null) ||
              (!userData.is_org_owner && userData.org_id == null)
            ) && (<PlanCard toggler={toggleExpand} />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarRedesigned;
