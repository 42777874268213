import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Screens/Layout";
import Home from "./Screens/Home";
import Helpers from "./Config/Helpers";
import Login from "./Screens/Auth/Login";
import Register from "./Screens/Auth/Register";
import ForgotPassword from "./Screens/Auth/ForgotPassword";
import Verify from "./Screens/Auth/Verify";
import VerifyForgotPassword from "./Screens/Auth/VerifyForgot";
import RecoverPassword from "./Screens/Auth/RecoverPassword";
import { ChatAppProvider } from "./Context/AppContext";
import ChatbotLandingPage from "./Screens/User/chat/ChatbotLandingPage";
import ChatLayout from "./Screens/User/chat/ChatLayout";
import Chatbot from "./Screens/User/chat/Chatbot";
import AdminLayout from "./Screens/Admin/Layout";
import AdminDashboard from "./Screens/Admin/Dashboard";
import AdminCategories from "./Screens/Admin/Categories";
import AdminPrompts from "./Screens/Admin/Prompts";
import AdminUsers from "./Screens/Admin/Users";
import AdminTemplates from "./Screens/Admin/Templates";
import AddTempTest from "./Screens/Admin/AddTempTest";
import AdminPackages from "./Screens/Admin/Packages";
import AdminTransactions from "./Screens/Admin/AdminTransactions";
import PlansPage from "./Screens/User/PlansPage";
import UserProfile from "./Screens/User/UserProfile";
import AdminSettings from "./Screens/Admin/Settings";
import GptModels from "./Screens/Admin/GptModels";
import TokenPackages from "./Screens/Admin/TokenPackages";
import CVTemplate from "./Screens/User/CVTemplate"
import Resume from "./Screens/User/Resume";

const Auth = ({ children, isAuth = true, isAdmin = false }) => {
  const location = useLocation();
  let user = Helpers.getItem("user", true);
  let token = Helpers.getItem("token");
  let loginTime = Helpers.getItem("loginTimestamp");
  let currentTime = new Date().getTime();
  let minutesPassed = Math.floor((currentTime - loginTime) / (1000 * 60));
  if (loginTime && minutesPassed >= 120) {
    localStorage.clear();
    Helpers.toast("error", "Session expired. Login again to continue");
    return <Navigate to="/login" />;
  }
  else if (isAuth) {
    if (!user || !token) {
      localStorage.clear();
      Helpers.toast("error", "Please login to continue");
      return <Navigate to="/login" />;
    }
    if (isAdmin && parseInt(user.user_type) !== 1) {
      Helpers.toast("error", "Access denied. Only admin allowed.");
      return <Navigate to="/chat/chat-interface" />;
    }
    if (!isAdmin && parseInt(user.user_type) === 1) {
      Helpers.toast(
        "error",
        "Access denied. Admins cannot access user routes."
      );
      return <Navigate to="/admin/dashboard" />;
    }
    return children;
  }
  else {
    if (user && token) {
      if (user.user_type === 1) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/chat/chat-interface" />;
      }
    }
    return children;
  }
};

const jsonData = {
  "name": "Jonathan Doe",
  "title": "Web Designer, Director",
  "email": "name@yourdomain.com",
  "phone": "(313) - 867-5309",
  "profile": "Progressively evolve cross-platform ideas before impactful infomediaries.",
  "skills": ["Web Design", "Interface Design", "Project Direction"],
  "technicalSkills": ["XHTML", "CSS", "Javascript", "PHP", "JQuery"],
  "experiences": [
    {
      "company": "Facebook",
      "title": "Senior Interface Designer",
      "description": "Enabled optimal core competencies through corporate relationships.",
      "dates": "2005-2007"
    },
    {
      "company": "Apple Inc.",
      "title": "Senior Interface Designer",
      "description": "Reconceptionalized multifunctional thinking through empowerment.",
      "dates": "2005-2007"
    }
  ],
  "education": [
    {
      "institution": "University of Birmingham",
      "degree": "BA (Hons) Graphic Design",
      "year": "2003-2006"
    }
  ]
};

function App() {
  return (
    <ChatAppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Auth isAuth={false}> <Login /> </Auth>} />

            <Route path="/" element={<Auth isAuth={false}><Home /></Auth>} />

            <Route path="/register" element={<Auth isAuth={false}> <Register /> </Auth>} />

            <Route path="/verify-email" element={   <Auth isAuth={false}>     <Verify />   </Auth> }/>

            <Route
              path="/forgot-password"
              element={
                <Auth isAuth={false}>
                  <ForgotPassword />
                </Auth>
              }
            />

            <Route
              path="/verify-email-password"
              element={
                <Auth isAuth={false}>
                  <VerifyForgotPassword />
                </Auth>
              }
            />

            <Route
              path="/recover-password"
              element={
                <Auth isAuth={false}>
                  <RecoverPassword />
                </Auth>
              }
            />

          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route
              path="/admin/dashboard"
              element={
                <Auth isAdmin={true}>
                  <AdminDashboard />
                </Auth>
              }
            />

            <Route
              path="/admin/users"
              element={
                <Auth isAdmin={true}>
                  <AdminUsers />
                </Auth>
              }
            />

            <Route
              path="/admin/categories"
              element={
                <Auth isAdmin={true}>
                  <AdminCategories />
                </Auth>
              }
            />

            <Route
              path="/admin/prompts"
              element={
                <Auth isAdmin={true}>
                  <AdminPrompts />
                </Auth>
              }
            />

            <Route
              path="/admin/packages"
              element={
                <Auth isAdmin={true}>
                  <AdminPackages />
                </Auth>
              }
            />
            <Route
              path="/admin/gptModels"
              element={
                <Auth isAdmin={true}>
                  <GptModels />
                </Auth>
              }
            />
            <Route
              path="/admin/tokenPackages"
              element={
                <Auth isAdmin={true}>
                  <TokenPackages />
                </Auth>
              }
            />

            <Route
              path="/admin/get-transactions"
              element={
                <Auth isAdmin={true}>
                  <AdminTransactions />
                </Auth>
              }
            />

            <Route
              path="/admin/templates"
              element={
                <Auth isAdmin={true}>
                  <AdminTemplates />
                </Auth>
              }
            />

            <Route
              path="/admin/AddTempTest"
              element={
                <Auth isAdmin={true}>
                  <AddTempTest />
                </Auth>
              }
            />

            <Route
              path="/admin/settings"
              element={
                <Auth isAdmin={true}>
                  <AdminSettings />
                </Auth>
              }
            />

          </Route>
          <Route path="/chat" element={<ChatLayout />}>
            <Route
              path="/chat/chat-interface"
              element={
                <Auth>
                  <ChatbotLandingPage />
                </Auth>
              }
            />

            <Route
              path="/chat/chatbot/:chat_id?"
              element={
                <Auth>
                  <Chatbot />
                </Auth>
              }
            />

          </Route>
          <Route path="/user" element={<ChatLayout />}>
            <Route
              path="/user/plans"
              element={
                <Auth>
                  <PlansPage />
                </Auth>
              }
            />
            <Route
              path="/user/profile"
              element={
                <Auth>
                  <UserProfile />
                </Auth>
              }
            />
            <Route
              path="/user/template/:chat_id"
              element={
                <Auth>
                  <CVTemplate />
                </Auth>
              }
            />
            <Route
              path="/user/resume/:chat_id"
              element={
                <Auth>
                  <Resume />
                </Auth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChatAppProvider>
  );
}

export default App;
