import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  jobTitle: {
    fontFamily: 'Lato Bold',
    fontSize: 12,
  },
  dates: {
    fontFamily: 'Lato Italic',
    fontSize: 10,
    marginBottom: 5,
  },
  description: {
    fontSize: 10,
    fontFamily: 'Lato',
    marginBottom: 10,
  },
});

const ExperienceEntry = ({ company, position, years, description }) => (
  <View style={styles.container}>
    <Text style={styles.jobTitle}>{position} at {company}</Text>
    <Text style={styles.dates}>{years}</Text>
    <Text style={styles.description}>{description}</Text>
  </View>
);

const Experience = ({ data }) => (
  <View>
    {data.map((experience, index) => (
      <ExperienceEntry
        key={index}
        company={experience.company}
        position={experience.position}
        years={experience.years}
        description={experience.description}
      />
    ))}
  </View>
);

export default Experience;
