import { useEffect, useRef, useState, useContext } from "react";
import { PromptTemplate } from "@langchain/core/prompts";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { ChatOpenAI } from "@langchain/openai";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { combineDocs } from "../../../Components/combineDocs";
import PageLoader from "../../../Components/Loader/PageLoader";
import { useRetriever } from "../../../Components/retriever";
import Helpers from "../../../Config/Helpers";
import { ChatAppContext } from "../../../Context/AppContext";
import MarkdownIt from "markdown-it";
import { jsPDF } from "jspdf";
import axios from "axios";
import { RunnableSequence } from "@langchain/core/runnables";
import { usePlan } from "../../../Context/PlanContext";
import { useApi } from "../../../Context/ApiContext";
import SelectInput from "../../../Components/Select";

const md = new MarkdownIt();

const Chatbot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { apiSettings } = useApi();

  const { selectedDocs, setSelectedDocs } = useContext(ChatAppContext);
  const retriever = useRetriever(selectedDocs);

  const { isPlanActive, userData, availableTokens, TokensAvailable, getProfileInfo } = usePlan();
  const [pageLoading, setPageLoading] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstResponse, setIsFirstResponse] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const { chatFile, setChatFile } = useContext(ChatAppContext);
  const [isWriting, setisWriting] = useState(false);
  const { chat_id } = useParams();
  const chatContainer = useRef(null);
  const [questions, setQuestions] = useState([]);

  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(apiSettings.openAiApiModel || "gpt-3.5-turbo");

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const { data } = await axios.get(`${Helpers.apiUrl}admin/gpt-models`, Helpers.authHeaders);
        setModelList(data);
        if (data.length > 0 && !selectedModel) {
          setSelectedModel(data[0].id);
        }
      } catch (error) {
        Helpers.toast("error", "Failed to fetch models.");
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const scrollToBottom = () => {
    if (chatContainer.current) {
      chatContainer.current.scrollTo({
        top: chatContainer.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const isHistory = location.state ? location.state.isHistory : null;

  // const downloadSingleMessagePDF = (message) => {
  //   try {
  //     const pdf = new jsPDF();
  //     const margins = { top: 10, bottom: 10, left: 10, right: 10 };
  //     const pageHeight = pdf.internal.pageSize.height;
  //     const lineHeight = 10;
  //     let cursorY = margins.top;

  //     const splitText = pdf.splitTextToSize(
  //       message,
  //       pdf.internal.pageSize.width - margins.left - margins.right
  //     );

  //     splitText.forEach((line) => {
  //       if (cursorY + lineHeight > pageHeight - margins.bottom) {
  //         pdf.addPage();
  //         cursorY = margins.top;
  //       }
  //       pdf.text(margins.left, cursorY, line);
  //       cursorY += lineHeight;
  //     });

  //     pdf.save("download.pdf");
  //   } catch (error) {
  //     Helpers.toast("error", "Failed to download PDF.");
  //     console.error("Error downloading PDF:", error);
  //   }
  // };
  const downloadSingleMessagePDF = () => {
    // navigate(`/user/template/${chat_id}`);
    navigate(`/user/resume/${chat_id}`);
  };

  const template = (id) => {
    navigate(`/user/templates-library/${Helpers.encryptString(id)}`);
  };

  const handleSuggestionClick = (suggestion) => {
    getResponse(new Event("submit"), suggestion);
  };

  const handleDownload = async (msg) => {
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}user/generateDocx`,
        { msg: msg },
        Helpers.authHeaders
      );
      const downloadElement = document.createElement("a");
      downloadElement.href = `${Helpers.basePath}/${response.data.filePath}`;
      downloadElement.download = "generated_doc.docx";
      downloadElement.target = "_blank";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      delfile(response.data.filePath);
      document.body.removeChild(downloadElement);
    } catch (error) {
      Helpers.toast("error", "Failed to generate DOCX.");
      console.error("Error generating DOCX:", error);
    }
  };

  const delfile = async (chatFile) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}user/deletefile`,
        { file: chatFile },
        Helpers.authHeaders
      );
    } catch (error) {
      Helpers.toast("error", "Failed to delete file.");
      console.error("Error deleting file:", error);
    }
  };

  const getChat = async () => {
    if (chat_id !== undefined) {
      setPageLoading(true);
      try {
        const { data } = await axios.get(`${Helpers.apiUrl}chat/get/${chat_id}`, Helpers.authHeaders);
        await getProfileInfo();
        if (data.content_ids) {
          setSelectedDocs(data.content_ids);
        } else {
          setSelectedDocs([]);
        }
        setPageLoading(false);
        if (data.messages.length === 0) {
          Helpers.toast("success", "Let's get started with your interaction");
        } else {
          console.log('messages lenght', data.messages.length);
          const lastMessage = data.messages[data.messages.length - 1];
          console.log(lastMessage)
          if (data.messages.length == 1 && (lastMessage.suggestions == null || lastMessage.suggestions.length == 0)) {
            suggestionQuestions()
          }
          setMessages(data.messages);
          if (lastMessage && Array.isArray(lastMessage.suggestions) && lastMessage.suggestions.length > 0) {
            setQuestions(lastMessage.suggestions);
          }
          scrollToBottom();
        }
      } catch (error) {
        setPageLoading(false);
        Helpers.toast("error", "An error occurred while fetching the chat.");
        console.error("Error fetching chat:", error);
      }
    }
  };

  const getResponse = async (e, inputText = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    if (!apiSettings.openAiApiKey || !selectedModel) {
      Helpers.toast("error", "Your API key or model is missing");
      return;
    }

    if (!retriever) {
      Helpers.toast("error", "Retriever is not initialized yet");
      return;
    }
    const message = inputText || userInput;
    if (!message.trim()) {
      Helpers.toast("error", "Please enter a message.");
      return;
    }

    setQuestions([]);
    setIsLoading(true);

    let msg = {
      message: message,
      user_id: Helpers.authUser.id,
      chat_id: chat_id,
      is_bot: 0,
    };
    setMessages((old) => [...old, msg]);

    let finalPrompt = "";
    const logPrompt = (prompt) => {
      finalPrompt = prompt;
      return prompt;
    };
    const llm = new ChatOpenAI({
      openAIApiKey: apiSettings.openAiApiKey,
      model: selectedModel,
    });

    const standAloneTemplate = `Given a question, convert the question to a standalone question. 
        Question: {question}
        standalone question:`;
    const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);

    const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." and direct the questioner to email help@docsphere.com. Don't try to make up an answer. Always speak as if you were chatting to a friend. Use **bold** for important points, *italic* for emphasis, and include new lines using '\\n'. 
      context: {context}
      question: {question}
      answer:
      `;
    const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);

    const standAloneQuestionChain = standAlonePrompt
      .pipe(logPrompt)
      .pipe(llm)
      .pipe(new StringOutputParser());

    const retrieverChain = RunnableSequence.from([
      (prevResult) => prevResult.standalone_question,
      async (standalone_question) => {
        const docs = await retriever.getRelevantDocuments(standalone_question);
        return combineDocs(docs);
      },
    ]);

    const answerChain = answerPrompt
      .pipe(logPrompt)
      .pipe(llm)
      .pipe(new StringOutputParser());

    const simulateChain = async () => {
      try {
        const standaloneQuestionResult = await standAloneQuestionChain.invoke({
          question: message,
        });

        const contextResult = await retrieverChain.invoke({
          standalone_question: standaloneQuestionResult,
        });

        const finalPromptData = await answerPrompt.format({
          context: contextResult,
          question: message,
        });

        return finalPromptData;
      } catch (error) {
        Helpers.toast("error", "Error processing the prompt.");
        console.error("Error in simulateChain:", error);
        throw error;
      }
    };

    try {
      const finalPromptData = await simulateChain();
      const data = new FormData();
      console.log(finalPrompt)
      data.append("chatid", chat_id);
      data.append("file", chatFile);
      data.append("input", message);
      data.append("lastprompt", finalPromptData);
      data.append("model", selectedModel);

      addMessage();
      setUserInput("");
      setisWriting(true);

      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const response = await fetch(`${Helpers.apiUrl}bot/response`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: data,
          signal,
        });

        if (!response.ok) {
          const errorData = await response.json();
          Helpers.toast("error", errorData.message || "Error in response.");
          setIsLoading(false);
        } else {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let allText = "";

          setIsLoading(false);
          setisWriting(false);

          async function processText({ done, value }) {
            if (done) {
              setisWriting(false);
              suggestionQuestions();
              await getProfileInfo();
              return;
            }

            let text = decoder.decode(value);
            allText += text;

            let withLines = text.replace(/\\n/g, "\n");
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1].message += withLines;
              return updatedMessages;
            });

            await reader.read().then(processText);
          }

          reader.read().then(processText);
        }
        setisWriting(false);
        setIsFirstResponse(false);
      } catch (error) {
        Helpers.toast("error", "Failed to fetch the bot response.");
        console.error("Error in fetch bot response:", error);
        setIsLoading(false);
        setisWriting(false);
      }
    } catch (error) {
      Helpers.toast("error", "Failed to process the response.");
      console.error("Error in getResponse:", error);
      setIsLoading(false);
      setisWriting(false);
    }
  };

  const updateTokens = async (totalTokensUsed) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}updateTokens`,
        {
          tokens: totalTokensUsed,
        },
        Helpers.authHeaders
      );
      console.log(`Tokens updated: ${totalTokensUsed}`);
    } catch (error) {
      Helpers.toast("error", "Failed to update tokens.");
      console.error("Error updating tokens:", error);
    }
  };

  const addMessage = () => {
    let msg = {
      message: "",
      user_id: Helpers.authUser.id,
      chat_id: chat_id,
      is_bot: 1,
    };
    setMessages((old) => [...old, msg]);
  };

  useEffect(() => {
    if (userData) {
      if (userData.permissions === 1) {
        setNoPermission(true);
      } else {
        setNoPermission(false);
      }
      setIsUserDataLoading(false);
    }
  }, [userData]);

  const suggestionQuestions = async () => {
    try {
      setQuestions([]);
      setisWriting(true);
      scrollToBottom();
      const formData = new FormData();
      formData.append("chatid", chat_id);
      formData.append("model", selectedModel);

      const { data } = await axios.post(`${Helpers.apiUrl}user/suggestionQuestions`, formData, Helpers.authHeaders);
      await getProfileInfo();
      setQuestions(data.questions);
      setisWriting(false);
      scrollToBottom();
    } catch (error) {
      Helpers.toast("error", "Failed to get suggestions");
      console.error("Error in getting suggestions:", error);
      setisWriting(false);
    }
  };

  useEffect(() => {
    chat_id == null || chat_id == undefined
      ? navigate("/chat/chat-interface")
      : getChat();
  }, [chat_id]);

  useEffect(() => {
    if (!pageLoading && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, pageLoading]);

  return (
    <>
      <div className="nk-content" style={{ height: "calc(100vh - 30px)" }}>
        <div className="container-xl h-100 p-0">
          <div className="nk-content-inner h-100">
            <div className="nk-content-body h-100  flex flex-column">
              <div
                className="flex-grow-1 scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin overflow-auto p-2 sm:p-0"
                ref={chatContainer}
              >
                {isFirstResponse && !isHistory ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                      color: "#333",
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    Your file has been uploaded successfully. You can now
                    enter your prompt.
                  </div>
                ) : (
                  messages.map((msg, index) => {
                    const isLastMessage = index === messages.length - 1;
                    return (
                      <div
                        key={index}
                        className={`container chat-box ${msg.is_bot == 0 ? "bg-white" : "bot-bubble"
                          }`}
                      >
                        <div className="">
                          <div className="">
                            <div className="align-center">
                              <div className={`chat-header ${msg.is_bot == 1 ? "" : "flex-row-reverse gap-2 px-2"}`}>
                                {msg.is_bot == 0 && (
                                  <div>
                                    <img
                                      className="chat-avatar"
                                      src={Helpers.serverImage(
                                        Helpers.authUser.profile_pic
                                      )}
                                      alt=""
                                    />
                                  </div>
                                )}
                                {msg.is_bot == 1 && (
                                  <div className="media media-middle media-circle text-bg-primary">
                                    <img src="/app/favicon.png" alt="" />
                                  </div>
                                )}
                                <span className={`chat-user d-flex ${msg.is_bot == 0 ? "justify-end" : " justify-between"}`}>
                                  <strong>
                                    {msg.is_bot == 1 ? "DocSphere.AI" : "You"}
                                  </strong>
                                  {msg.is_bot == 1 && (
                                    <div className="token-info" style={{ margin: "10px" }}>
                                      <p>Total Tokens: {userData?.org_id ? userData.organization.no_tokens : userData.no_tokens}</p>
                                      <p>Available Tokens: {Math.max(availableTokens, 0)}</p>
                                    </div>
                                  )}
                                </span>

                              </div>
                            </div>
                            <div className="chat-divider"></div>
                          </div>
                          <div className="">
                            <div className={`message ${msg.is_bot == 1 ? "" : "text-end !pb-2 pt-4"}`}>
                              <ChatGPTFormatter
                                response={msg.message}
                                writing={(messages.length - 1 === index && isWriting)}
                              />
                            </div>
                            {msg.is_bot == 1 && isLastMessage && Array.isArray(questions) && questions.length > 0 && (
                              <div className="pb-4 flex flex-wrap justify-center gap-2">
                                {questions.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="card suggestion-card border transition !duration-400 border-gray-500 suggestions-card hover:!border-gray-900 cursor-pointer"
                                    onClick={() => handleSuggestionClick(suggestion.trim())}
                                  >
                                    <p className="text-sm suggestions-text text-black">
                                      {suggestion.trim()}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                            {msg.is_bot == 1 && (
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn mx-1"
                                  style={{
                                    color: "#d308bd",
                                    border: "1px solid #d308bd",
                                    borderRadius: "20px",
                                  }}
                                  onClick={() =>
                                    downloadSingleMessagePDF(msg.message)
                                  }
                                >
                                  Download PDF
                                </button>
                                <button
                                  className="btn"
                                  style={{
                                    color: "#d308bd",
                                    border: "1px solid #d308bd",
                                    borderRadius: "20px",
                                  }}
                                  onClick={() =>
                                    handleDownload(md.render(msg.message))
                                  }
                                >
                                  Download DOCX
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <div
                className="px-md-4 px-2 pt-4 special-margin rounded"
                style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
              >
                <div>
                  <div className="d-flex flex-wrap align-items-start mb-3">
                    {/* Model dropdown */}
                    <div className="flex-shrink-0 w-100">
                      <SelectInput
                        cols="4"
                        label="Model"
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        options={modelList}
                        isObject={true}
                        optionValue="value"
                        optionLabel="name"
                        placeholder="Select Model"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap align-items-start">
                    <div className="flex-grow-1 mb-2 w-20">
                      <textarea
                        className="chatbot-input w-100"
                        style={{ minHeight: "100px" }}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        placeholder={
                          isPlanActive === 'Expired'
                            ? "You cannot use the bot, please buy/renew the plan first"
                            : noPermission
                              ? "You don't have write permission."
                              : "Enter a prompt here"
                        }
                        disabled={noPermission || isPlanActive === 'Expired'}
                      ></textarea>
                    </div>

                    <div className="ml-2 mb-2 w-80 w-md-auto">
                      <button
                        type="submit"
                        className="btn btn-md btn-primary w-100"
                        style={{
                          backgroundColor: "#671BB3",
                          color: "#ffffff",
                          borderRadius: "20px",
                        }}
                        disabled={isLoading || isPlanActive === 'Expired' || noPermission}
                        onClick={() => {
                          getResponse("");
                        }}
                      >
                        <span className="mx-2">Send</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
