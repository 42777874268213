import React, { createContext, useContext, useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [apiSettings, setApiSettings] = useState({
    openAiApiKey: "",
    openAiApiModel: "",
    supabaseApiKey: "",
    supabaseUrl: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchApiKeys = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${Helpers.apiUrl}settings`, Helpers.authHeaders);
      
      setApiSettings({
        openAiApiKey: data.openai_api_key || "",
        openAiApiModel: data.model || "",
        supabaseApiKey: data.supabase_api_key || "",
        supabaseUrl: data.supabase_url || "",
      });
    } catch (error) {
      console.error("Error fetching API keys: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  return (
    <ApiContext.Provider value={{ apiSettings, isLoading, fetchApiKeys }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
