import React, { useEffect, useState } from "react";
import logo from "../../logo-duo.png";
import axios from "axios";
import PageLoader from "../../Components/Loader/PageLoader";
import Helpers from "../../Config/Helpers";
import dayjs from "dayjs";
import { usePlan } from "../../Context/PlanContext";
import { useLocation, useNavigate } from "react-router-dom";

const PlansPage = () => {
  const [TokenPackages, setTokenPackages] = useState([]);

  const getTokenPackages = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}token-packages`, Helpers.authHeaders);
      setTokenPackages(response.data);
    } catch (error) {
      console.log("Error Fetching Token Packages:", error);
    }
  };

  const handleBuyTokens = async (tokenPackage) => {
    const currentDate = dayjs().format("YYYY-MM-DD");

    const data = {
      user_id: Helpers.authUserId,
      package_id: tokenPackage.id,
      amount: tokenPackage.price,
      purchase_date: currentDate,
    };

    localStorage.setItem("selectedPlan", JSON.stringify(tokenPackage));
    localStorage.setItem("actionType", "buyTokens");

    setLoader(true);
    try {
      const response = await axios.post(`${Helpers.apiUrl}pay`, { amount: data.amount }, Helpers.authHeaders);
      console.log(response)
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      setLoader(false);
    } catch (error) {
      Helpers.toast("error", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPricingPlans();
    getTokenPackages();
  }, []);
  const [loader, setLoader] = useState(false);
  const [Plans, setPlans] = useState([]);
  const [OrgName, setOrgName] = useState("");
  const [showOrgNamePopup, setShowOrgNamePopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isPlanActive, userData, TokensAvailable, getProfileInfo } = usePlan();
  const getPricingPlans = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `${Helpers.apiUrl}packages/all-packages`,
        Helpers.authHeaders
      );
      setPlans(response.data);
      setLoader(false);
    } catch (error) {
      console.log("Error Fetching Plans:", error);
      setLoader(false);
    }
  };

  const verifyPayment = async (trxref, reference) => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}verify-payment`, { trxref, reference }, Helpers.authHeaders);
      if (response.data.status === "success") {
        const orgName = localStorage.getItem("organizationName");
        const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan"));
        const currentDate = dayjs().format("YYYY-MM-DD");

        const data = {
          user_id: Helpers.authUserId,
          package_id: selectedPlan.id,
          amount: selectedPlan.package_price,
          purchase_date: currentDate,
          org_name: orgName ? orgName : undefined,
          token_purchase: true
        };

        try {
          const response = await axios.post(
            `${Helpers.apiUrl}transactions/save-transaction`,
            data,
            Helpers.authHeaders
          );
          Helpers.toast("success", data.token_purchase ? response.data.message : "Plan selected successfully");
          getProfileInfo();
          navigate("/chat/chat-interface");
        } catch (error) {
          Helpers.toast("error", error.response.data.message);
        }
      } else {
        Helpers.toast("error", "Payment verification failed");
      }
    } catch (error) {
      Helpers.toast("error", "Error verifying payment");
      console.error("Error verifying payment:", error.message);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const trxref = query.get("trxref");
    const reference = query.get("reference");

    if (trxref && reference) {
      verifyPayment(trxref, reference);
    }

    getPricingPlans();
  }, []);

  const getNestedData = (data) => {
    while (data && typeof data === "object" && "data" in data) {
      data = data.data;
    }
    return data;
  };

  const handleBuyPlan = async (plan) => {
    if (userData?.org_id !== null && userData?.organization?.org_name) {
      proceedWithTransaction(plan, userData.organization.org_name);
    } else if (plan.package_type === "Organization") {
      setSelectedPlan(plan);
      setShowOrgNamePopup(true);
    } else {
      proceedWithTransaction(plan);
    }
  };

  const proceedWithTransaction = async (plan, orgName = null) => {
    const currentDate = dayjs().format("YYYY-MM-DD");

    const data = {
      user_id: Helpers.authUserId,
      package_id: plan.id,
      amount: plan.package_price,
      purchase_date: currentDate,
      org_name: orgName ? orgName : undefined,
    };

    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    localStorage.setItem("actionType", "buyPlan");

    setLoader(true);
    try {
      const response = await axios.post(`${Helpers.apiUrl}pay`, { amount: data.amount }, Helpers.authHeaders);
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      setLoader(false);
    } catch (error) {
      Helpers.toast("error", error);
      setLoader(false);
    }
  };

  const handleOrgNameSubmit = () => {
    if (OrgName.trim() === "") {
      Helpers.toast("error", "Please enter the organization's name");
    } else {
      localStorage.setItem("organizationName", OrgName);
      proceedWithTransaction(selectedPlan, OrgName);
      setShowOrgNamePopup(false);
      setOrgName("");
    }
  };

  const filteredPlans = Plans.filter((plan) => {
    if (userData?.org_id == null && !userData?.is_org_owner && userData?.transactions.length === 0) {
      return true;
    } else if (userData?.org_id == null && !userData?.is_org_owner && userData?.transactions != null) {
      return plan.package_type === "Single User";
    } else if (userData?.is_org_owner && userData?.org_id) {
      return plan.package_type === "Organization";
    }
    return true;
  });

  if (loader) {
    return <PageLoader />;
  }

  return (
    <div className="overflow-y-auto scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin px-2">
      <div className="d-flex justify-content-between align-items-center w-full py-2 px-8 border-bottom border-lightgrey">
        <div className="d-flex align-items-center gap-3">
          <a href="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "120px",
                height: "40px",
                padding: "5px",
                borderRadius: "4px",
              }}
            />
          </a>
          <div className="overflow-hidden">
            <p className="badge text-custom px-2 py-1 ">Pricing Plans</p>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto d-flex justify-content-center align-items-center py-4">
        <div className="d-flex flex-column gap-3 position-relative mb-4">
          <div className="bg-light rounded p-3 d-flex flex-column flex-sm-row justify-content-between align-items-start text-center text-sm-start">
            {!TokensAvailable && (isPlanActive === "Expired" || isPlanActive === "Active") && (
              <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">Your Tokens Are Finished, Renew Your Plan</p>
            )}
            {isPlanActive === "Active" && TokensAvailable && (
              <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">You're Already Subscribed To A Plan</p>
            )}
            {isPlanActive === "Expired" && (
              <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">Your Plan Is Expired, Renew Your Plan</p>
            )}
            {isPlanActive !== "Expired" && isPlanActive !== "Active" && (
              <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">Choose The Plan That Best Suits You</p>
            )}
          </div>

          <div
            className={`grid ${isPlanActive === "Active" && TokensAvailable ? "pointer-events-none opacity-50" : "block"
              } grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 pb-8 justify-center`}
          >
            {filteredPlans.length > 0 ? (
              filteredPlans.map((plan) => (
                <div
                  key={plan.id}
                  className="border-2 border-gray-200 hover:border-gray-500 cursor-pointer transition duration-300 rounded p-6 bg-white flex flex-col justify-between min-w-[280px] max-w-[320px] mx-auto"
                >
                  <h3 className="text-xl font-bold mb-4">{plan.package_name}</h3>

                  <div className="flex justify-between items-center mb-4">
                    <p className="text-lg font-semibold">${plan.package_price}</p>
                    <div className="flex items-center">
                      {plan.package_users === 1 ? (
                        <i className="fas fa-user text-gray-600 mr-2"></i>
                      ) : (
                        <i className="fas fa-users text-gray-600 mr-2"></i>
                      )}
                      <p className="text-md text-gray-700">{plan.package_users}</p>
                    </div>
                    <div className="flex gap-1 align-items-center">
                      <i className="fa fa-coins text-gray-600"></i>
                      <p className="text-lg font-semibold">{plan.no_tokens}</p>
                    </div>
                  </div>

                  <ul className="text-gray-600 mb-6">
                    {plan.package_description.split(",").map((feature, index) => (
                      <li key={index} className="mb-2">
                        {feature.trim()}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => handleBuyPlan(plan)}
                    className="w-full btn btn-dark text-white py-2 rounded-md hover:bg-blue-900 transition mt-auto"
                    disabled={loader}
                  >
                    Buy Now
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No Plans Available</p>
            )}
          </div>
        </div>
      </div>
      
      {!TokensAvailable && (isPlanActive !== "Expired" || isPlanActive === "Active") &&
        <div className="overflow-y-auto d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column gap-3 position-relative mb-16">
            <div className="bg-light rounded p-3 d-flex flex-column flex-sm-row justify-content-between align-items-start text-center text-sm-start">
              <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">Your Tokens Are Finished, Buy Tokens</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 pb-8 justify-center">
              {TokenPackages.map((tokenPackage) => (
                <div
                  key={tokenPackage.id}
                  className="border-2 border-gray-200 hover:border-gray-500 cursor-pointer transition duration-300 rounded p-6 bg-white flex flex-col justify-between min-w-[280px] max-w-[320px] mx-auto"
                >
                  <h3 className="text-xl font-bold mb-4">{tokenPackage.package_name}</h3>
                  <p>Tokens: {tokenPackage.no_tokens}</p>
                  <p>Price: ${tokenPackage.price}</p>
                  <button
                    onClick={() => handleBuyTokens(tokenPackage)}
                    className="w-full btn btn-dark text-white py-2 rounded-md hover:bg-blue-900 transition mt-auto"
                    disabled={loader}
                  >
                    Buy Tokens
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

      {showOrgNamePopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md w-1/2">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-bold mb-2">Enter Organization's Name</h2>
              <button
                onClick={() => setShowOrgNamePopup(false)}
                className="text-gray-600 hover:text-gray-800"
              >
                ✖️
              </button>
            </div>
            <input
              type="text"
              value={OrgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter organization's name"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            <button
              onClick={handleOrgNameSubmit}
              className="bg-blue-500 hover:bg-blue-700 transition duration-200 text-white font-bold py-2 px-4 rounded-md mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlansPage;
